<template>
  <div class="school_head">
    <img
      :src="coverImgUrl ? constant.URL + coverImgUrl : baseImg"
      class="school_head_img"
      alt=""
    />
    <div class="school_head_mask"></div>
    <div class="school_head_box">
      <div class="school_head_box_left">
        <div class="school_head_box_left_t">
          <div class="school_head_box_left_t_l">
            <img
              :src="
                constant.URL + $store.state.schoolInfo.atteSchool.schoolLogo
              "
              alt=""
              v-if="!$store.state.userInfo.teacherId"
            />
            <p v-if="!$store.state.userInfo.teacherId">
              {{ $store.state.schoolInfo.atteSchool.schoolName }}
            </p>
            <img
              :src="
                constant.URL + $store.state.findteachersInfo.atteTeacher.photo
              "
              alt=""
              v-if="
                $store.state.userInfo.teacherId &&
                $store.state.findteachersInfo.atteTeacher.photo
              "
            />
            <img
              src="../../assets/img/school/Icon_Teacher_Ks.png"
              alt=""
              v-if="
                $store.state.userInfo.teacherId &&
                !$store.state.findteachersInfo.atteTeacher.photo
              "
            />
            <p v-if="$store.state.userInfo.teacherId" class="tec-title-info">
              {{ $store.state.schoolInfo.atteSchool.schoolName }}
              {{ $store.state.findteachersInfo.atteTeacher.name }}
            </p>
          </div>
          <div class="school_head_box_left_t_r">
            <p>积分</p>
            <p
              class="integral-val"
              @click="toIntegral"
              v-if="!$store.state.userInfo.teacherId"
            >
              {{ $store.state.schoolInfo.schoolIntegrals }}<span>></span>
            </p>
            <p
              class="integral-val"
              @click="toTeacherIntegral"
              v-if="$store.state.userInfo.teacherId"
            >
              {{ $store.state.findteachersInfo.atteTeacher.integral
              }}<span>></span>
            </p>
          </div>
        </div>
        <div
          class="school-box-info"
          v-if="$store.state.schoolInfo.atteSchool.schoolFlagImg"
        >
          <div class="school-box-info-icon">
            <img
              :src="$store.state.schoolInfo.atteSchool.schoolFlagImg"
              alt=""
            />
          </div>
          <div class="school-box-info-int">
            <p v-if="!$store.state.userInfo.teacherId">
              班级: <span>{{ $store.state.schoolInfo.gradeCount }}</span>
            </p>
            <p
              v-if="!$store.state.userInfo.teacherId"
              class="authentication-tec"
            >
              认证教师: <span>{{ $store.state.schoolInfo.teaCount }}</span>
            </p>
            <p v-if="!$store.state.userInfo.teacherId">
              认证幼儿: <span>{{ $store.state.schoolInfo.stuCount }}</span>
            </p>
            <p v-if="$store.state.userInfo.teacherId">
              班级: <span>{{ $store.state.findteachersInfo.gradeCount }}</span>
            </p>
            <p
              v-if="$store.state.userInfo.teacherId"
              class="authentication-tec"
            >
              认证幼儿:
              <span>{{ $store.state.findteachersInfo.stuCount }}</span>
            </p>
          </div>
          <div class="school-box-info-desc">
            {{ $store.state.schoolInfo.atteSchool.intro }}
          </div>
        </div>
        <div class="school-box-info-null" v-else>
          <p
            class="school_head_box_left_desc"
            :title="$store.state.schoolInfo.atteSchool.intro"
            v-if="!$store.state.userInfo.teacherId"
          >
            {{ $store.state.schoolInfo.atteSchool.intro }}
          </p>
          <div class="school_class">
            <div class="school_class_del">
              <ul>
                <li v-if="!$store.state.userInfo.teacherId">
                  班级: <span>{{ $store.state.schoolInfo.gradeCount }}</span>
                </li>
                <li class="req" v-if="!$store.state.userInfo.teacherId"></li>
                <li v-if="!$store.state.userInfo.teacherId">
                  认证教师: <span>{{ $store.state.schoolInfo.teaCount }}</span>
                </li>
                <li class="req" v-if="!$store.state.userInfo.teacherId"></li>
                <li v-if="!$store.state.userInfo.teacherId">
                  认证幼儿: <span>{{ $store.state.schoolInfo.stuCount }}</span>
                </li>
                <li v-if="$store.state.userInfo.teacherId">
                  班级:
                  <span>{{ $store.state.findteachersInfo.gradeCount }}</span>
                </li>
                <li class="req" v-if="$store.state.userInfo.teacherId"></li>
                <li v-if="$store.state.userInfo.teacherId">
                  认证幼儿:
                  <span>{{ $store.state.findteachersInfo.stuCount }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="school_head_box_right">
        <div class="school_head_box_right_item">
          <p class="school_head_box_right_item_tit">
            <span class="school_head_box_right_item_tit_s">
              <img src="../../assets/img/newschool/Icon_BD.png" alt="" />
              <span>数据展示</span>
            </span>

            <router-link :to="{ path: '/school/ranking' }" class="details"
              >详情 ></router-link
            >
          </p>
          <ul class="school_head_box_right_item_list">
            <li>
              <span>积分数据</span>
              <span>{{ rankList.rankingIntegral }}</span>
            </li>
            <li>
              <span>学习数据</span>
              <span>{{ rankList.rankingStudy }}</span>
            </li>
            <li>
              <span>足特数据</span>
              <span>{{ rankList.rankingZT }}</span>
            </li>
            <li>
              <span>示范数据</span>
              <span>{{ rankList.rankingSFY }}</span>
            </li>
            <li>
              <span>活跃数据</span>
              <span>{{ rankList.rankingActive }}</span>
            </li>
          </ul>
        </div>
        <div
          class="school_head_box_right_item"
          v-if="$store.state.schoolInfo.atteSchool.auditState != 2"
        >
          <p class="school_head_box_right_item_tit bigScreen">
            <!-- <span class="school_head_box_right_item_tit_s">
              <img src="../../assets/img/newschool/Icon_DP.png" alt="" />
              <span>大屏</span>
            </span> -->
            <!-- <span></span> -->

            <span>智慧幼儿园</span>
            <!-- <router-link :to="{ path: '/datav' }" class="details"

              >详情 ></router-link
            > -->
            <span @click="bindToZh" class="details">详情 ></span>
          </p>
          <div class="school_head_box_right_item_img">
            <img src="../../assets/img/newschool/Pic_XYZX_ZHYEY.png" alt="" />
          </div>
        </div>
        <div class="school_head_box_right_item_zty" v-else>
          <div>
            <p class="school_head_box_right_item_tit">
              <span class="school_head_box_right_item_tit_s">
                <img src="../../assets/img/newschool/Icon_DP.png" alt="" />
                <span>智慧幼儿园</span>
              </span>
              <!-- <span></span> -->
              <span @click="bindToZh" class="details">详情 ></span>
            </p>
            <div class="school_head_box_right_item_img">

              <img
                src="../../assets/img/newschool/Pic_XYZX_ZH_Half.png"
                alt=""
              />


            </div>
          </div>
          
          <div @click="showPb">
            <p class="school_head_box_right_item_tit">
              <span class="school_head_box_right_item_tit_s">
                <img src="../../assets/img/newschool/Icon_Pb.png" alt="" />
                <span>牌匾</span>
              </span>
              <!-- <span></span> -->
              <a class="details" href="javascript:;">详情 ></a>
            </p>
            <div class="school_head_box_right_item_img">
              <img src="../../assets/img/newschool/Pic_Pb.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="电子牌匾"
      center
      :visible.sync="dialogPb"
      width="35%"
      class="my-upload-dialog"
    >
      <div class="dzpb">
        <img
          class="dzpb_logo"
          src="../../assets/img/newschool/Pic_Logo.png"
          alt=""
        />
        <p class="dzpb_name">全国足球特色幼儿园</p>
        <p class="dzpb_school">{{ pbInfo.schoolName }}</p>
        <p class="dzpb_desc">{{ pbInfo.slogan }}</p>
        <div class="dzpb_box">
          <div class="dzpb_box_l">
            <p>授牌编号</p>
            <p>{{ pbInfo.sn }}</p>
          </div>
          <div class="dzpb_box_c">
            <img
              :src="pbInfo.code"
              style="width: 70px; height: 68px; margin: 10px"
            />
          </div>
          <div class="dzpb_box_r">
            <p>授牌日期</p>
            <p>{{ time }}</p>
          </div>
        </div>
        <p class="dzpb_dw">全国青少年校园足球工作领导小组办公室</p>
      </div>
      <p class="dzpb_info">请扫二维码查看电子牌匾详情</p>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!-- <component :is="currentComp"></component> -->
    <!-- <div class="go-old" @click="goClick"></div> -->
  </div>
</template>

<script>
// import Process from "./process.vue";
// import TeacherProcess from "./teacherProcess.vue";
export default {
  props: {
    nav: {
      type: Array,
      default: () => {
        return [
          {
            type: "img",
            url: "",
          },
        ];
      },
    },
  },
  data() {
    return {
      isLook: true,
      coverImgUrl: this.$store.state.schoolInfo.atteSchool.imageBg,
      baseImg: require("../../assets/img/school/school_bg.png"),
      rankList: {}, //榜单列表
      currentComp: "",
      dialogPb: false,
      pbInfo: {},
      time: "",
    };
  },
  mounted() {
    if (this.$store.state.userInfo.teacherId) {
      this.currentComp = "TeacherProcess";
      this.$store.dispatch(
        "getFindTeachersInfo",
        this.$store.state.userInfo.teacherId
      );
    } else {
      this.currentComp = "Process";
    }
    this.getSchoolRanking();
    if (this.$store.state.schoolInfo.atteSchool.auditState == 2) {
      this.getBottomInfo();
    }
  },
  methods: {
    bindLook() {
      console.log(12313);
      if (this.isLook) {
        this.isLook = false;
      } else {
        this.isLook = true;
      }
    },
    repFuhao(v) {
      console.log(v);
      let d = v.replace(/./g, "*");
      return d;
    },
    toIntegral() {
      this.$router.push({
        path: "/integral",
      });
    },
    toTeacherIntegral() {
      this.$router.push({
        path: "/integralTeacherList",
        query: {
          userId: this.$store.state.userInfo.userId,
        },
      });
    },
    getSchoolRanking() {
      //获取榜单列表
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.nAdmin.getSchoolRanking(data).then((res) => {
        console.log(res, "res");
        this.rankList = res.data;
      });
    },
    showPb() {
      this.dialogPb = true;
    },
    getBottomInfo() {
      let data = {
        schoolId: this.$store.state.schoolInfo.atteSchool.id,
      };
      this.api.admin.atteSchoolStatisticalInfo(data).then((res) => {
        this.pbInfo = res.data;
        // let time = getDateDay(res.data.createtime);
        // this.pbInfo.time = time;
        this.pbInfo.code = "https://oss.prefootball.cn" + res.data.plaque;
      });
      this.api.admin.getAuthorizationTime(data.schoolId).then((res) => {
        this.time = res.data.verifyDate;
        console.log("===", this.time);
      });
    },
    // goClick() {
    //   location.href = this.constant.OLD_VERSION;
    // },
    // 判断是否是智慧
    bindToZh() {

      this.$store.dispatch("checkAuth", {
        id: 37,
        fn: this.hasAuth,
        that: this,
        fail: this.noAuth,
      });

    },

    hasAuth() {
      this.$router.push({
        path: "/purchase",
      });
    },
    noAuth() {
      this.$router.push({
        path: "/unpurchased",
      });
    },

   
  },
  components: {
    // Process,
    // TeacherProcess,
  },
};
</script>

<style lang="scss" scoped>
.school_head {
  width: 100%;
  height: 575px;
  position: relative;
  // background: url(../../assets/img/school/school_bg.png) no-repeat;
  // background-size: 100% 100%;
  .school_head_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .school_head_mask {
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .school_head_box {
    position: relative;
    // z-index: 0;
    width: 1407px;
    height: 100%;
    margin: 0 auto;
    @include flex(row, space-between, top);

    .school_head_box_left {
      width: 700px;

      .school_head_box_left_t {
        padding-top: 58px;
        width: 100%;

        padding-bottom: 14px;
        @include flex(row, space-between, center);

        .school_head_box_left_t_l {
          @include flex(row, "flex-start", "center");

          > img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
          }
          > p {
            padding-left: 22px;
            font-size: 42px;
            font-weight: bold;
            color: #ffffff;
            line-height: 60px;
          }
        }
        .school_head_box_left_t_r {
          text-align: right;
          font-size: 18px;
          color: #ffffff;

          > p:nth-child(1) {
            opacity: 0.7;
          }
          > p:nth-child(2) {
            font-size: 24px;
            font-weight: bold;
            color: #ffb922;
            opacity: 1 !important;
            > span {
              padding-left: 4px;
            }
          }
          .integral-val:hover {
            color: #ffd444;
            cursor: pointer;
          }
        }
      }
      .school_head_box_left_desc {
        margin-top: 34px;
        font-size: 20px;
        font-weight: 400;
        color: #ededed;
        line-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* 这个表示要显示几行 */
        -webkit-box-orient: vertical;
      }
      .school_class {
        // text-align: center;
        margin-top: 20px;
        .school_class_del {
          ul {
            display: flex;
            color: #fff;
            font-size: 18px;
            align-items: center;
            justify-content: flex-start;
            .req {
              width: 2px;
              height: 12px;
              background: #abaaaa;
              margin: 0 20px;
            }
            span {
              color: #fac000;
              font-weight: bold;
            }
          }
        }
      }
    }
    .school_head_box_right {
      margin-top: 54px;
      width: 618px;
      @include flex(row, flex-start, top);
      .school_head_box_right_item {
        padding: 0 20px;
        width: 268px;
        height: 354px;
        background: url("../../assets/img/newschool/Pic_Bg_JF.png") no-repeat;
        background-size: 100% 100%;
        .school_head_box_right_item_tit {
          // padding-left: 32px;
          // background: url("../../assets/img/newschool/Icon_BD.png") no-repeat
          //   left center;
          height: 46px;
          line-height: 46px;
          font-size: 20px;
          font-weight: 400;
          color: #ededed;
          @include flex(row, space-between, center);

          .school_head_box_right_item_tit_s {
            @include flex(row, flex-start, center);
            > img {
              margin-right: 10px;
              width: 19px;
              height: 23px;
            }
          }

          > a {
            font-size: 16px;
            font-weight: 400;
            color: #ff4952;
          }
          .details {
            padding-top: 3px;
            font-size: 16px;
            font-weight: 400;
            color: #ff4952;
            cursor: pointer;
          }
          .details:hover {
            color: #ff9999;
          }
        }
        .bigScreen {
          .school_head_box_right_item_tit_s {
            > img {
              width: 23px;
              height: 23px;
            }
          }
        }
        .school_head_box_right_item_list {
          margin-top: 20px;
          > li {
            padding: 0 20px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            opacity: 0.9;
            @include flex(row, space-between, center);
          }
          > li:nth-of-type(odd) {
            background: url("../../assets/img/newschool/Pic_Bg_Ph_RL.png")
              no-repeat;
          }
        }
        .school_head_box_right_item_img {
          > img {
            margin-top: 40px;
            width: 270px;
            height: 200px;
          }
        }
      }
      .school_head_box_right_item_zty {
        > div {
          padding: 0 20px;
          width: 268px;
          height: 171px;
          background: url("../../assets/img/newschool/Pic_Pb_Bg.png") no-repeat;
          background-size: cover;
        }
        > div:nth-child(1) {
          margin-bottom: 12px;
          background: url("../../assets/img/newschool/Pic_DP_dpBg.png")
            no-repeat;
          background-size: cover;
        }

        .school_head_box_right_item_tit {
          // padding-left: 32px;
          // background: url("../../assets/img/newschool/Icon_BD.png") no-repeat
          //   left center;
          height: 46px;
          line-height: 46px;
          font-size: 20px;
          font-weight: 400;
          color: #ededed;
          @include flex(row, space-between, center);

          .school_head_box_right_item_tit_s {
            @include flex(row, flex-start, center);
            > img {
              margin-right: 10px;
              width: 19px;
              height: 23px;
            }
          }

          > a {
            font-size: 16px;
            font-weight: 400;
            color: #ff4952;
          }
          .details {
            padding-top: 3px;
            font-size: 16px;
            font-weight: 400;
            color: #ff4952;
            cursor: pointer;
          }
          .details:hover {
            color: #ff9999;
          }
        }
        .school_head_box_right_item_list {
          margin-top: 20px;
          > li {
            padding: 0 20px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            opacity: 0.9;
            @include flex(row, space-between, center);
          }
          > li:nth-of-type(odd) {
            background: url("../../assets/img/newschool/Pic_Bg_Ph_RL.png")
              no-repeat;
          }
        }
        .school_head_box_right_item_img {
          > img {
            width: 250px;
            height: 121px;
          }
        }
      }
    }
  }
  .go-old {
    position: absolute;
    left: 0px;
    top: 104px;
    width: 146px;
    height: 48px;
    background: url("../../assets/img/school/Btn_Fhjb.png") no-repeat;
    background-size: 146px 48px;
    cursor: pointer;
  }
  // .school_head_list {
  //   padding-top: 73px;
  // }
  // .school_mes {
  //   width: 783px;
  //   height: 100px;
  //   display: flex;
  //   align-items: center;
  //   padding: 0 10px;
  //   border-radius: 100px;
  //   background: url(../../assets/img/school/school_mes.png) no-repeat;
  //   background-size: 100% 100%;
  //   margin: 0 auto;
  //   .head_item_name {
  //     height: 74px;
  //     margin-left: 13px;
  //   }
  // }
  // .head_item_img {
  //   width: 73px;
  //   height: 73px;
  //   border-radius: 73px;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 73px;
  //   }
  // }
  // .head_item_name {
  //   flex: 1;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }
  // .school_head_title {
  //   font-size: 24px;
  //   color: #fff;
  //   display: flex;
  //   align-items: center;
  //   .bs {
  //     font-size: 12px;
  //     padding: 1px 6px;
  //     background: red;
  //     border-radius: 5px;
  //   }
  // }
  // .school_head_total {
  //   font-size: 20px;
  //   color: #fff;
  //   span {
  //     font-size: 30px;
  //     color: #fac000;
  //   }
  // }

  // .head_item_to {
  //   width: 84px;
  //   height: 84px;
  //   background: #fcbd0d;
  //   border-radius: 84px;
  //   font-size: 20px;
  //   font-weight: bold;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   a {
  //     color: #fff;
  //     span {
  //       display: block;
  //     }
  //   }
  // }
}
.school-box-info {
  display: flex;
  border-top: 1px dashed #fff;
  padding-top: 20px;
  .school-box-info-icon {
    img {
      width: 90px;
      height: 124px;
    }
  }
  .school-box-info-int {
    width: 180px;
    margin-top: 8px;
    margin-left: 24px;
    p {
      color: #fff;
      font-size: 18px;
      align-items: center;
      justify-content: flex-start;
      span {
        color: #fac000;
        font-weight: 700;
      }
    }
    .authentication-tec {
      padding: 20px 0;
    }
  }
  .school-box-info-desc {
    width: 410px;
    margin-top: 8px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
}
.tec-title-info {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.dzpb {
  margin: 22px auto 10px auto;
  padding: 0 25px 30px 25px;
  background: url("../../assets/img/newschool/Pic_Pb_ysBg.png") no-repeat;
  background-size: 478px 100%;
  text-align: center;
  width: 428px;
  height: auto;
  color: #000000;
  .dzpb_logo {
    margin-top: 34px;
    width: 113px;
    height: 113px;
  }
  .dzpb_name {
    font-size: 24px;
    font-weight: bold;
  }
  .dzpb_school {
    margin: 6px auto;
    font-size: 16px;
    font-weight: bold;
  }
  .dzpb_desc {
    font-size: 14px;
  }
  .dzpb_box {
    margin-top: 6px;
    @include flex(row, center, center);
    font-size: 14px;
    > div:nth-child(1) {
      text-align: right;
      > p:nth-child(2) {
        margin-top: 6px;
        font-size: 18px;
        font-weight: bold;
        color: #111111;
      }
    }
    > div:nth-child(3) {
      text-align: left;
      > p:nth-child(2) {
        margin-top: 6px;
        font-size: 18px;
        font-weight: bold;
        color: #111111;
      }
    }
  }
  .dzpb_dw {
    font-size: 16px;
    font-weight: bold;
    color: #111111;
  }
}
.dzpb_info {
  text-align: center;
  font-size: 16px;
  color: #888888;
}
</style>
<style lang="scss">
.my-upload-dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__header {
    padding-bottom: 20px;
    background: #cb1b32;
    border-radius: 10px 10px 0 0;
    font-size: 18px;
    font-weight: bold;

    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__close {
      color: #ffffff;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .my-upload-dialog-btn {
    width: 100px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    border: none;
    background: linear-gradient(232deg, #cb1930, #ff4054);
    box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
    border-radius: 6px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
  }
  .el-switch.is-checked .el-switch__core {
    border-color: #cb1b32;
    background-color: #cb1b32;
  }
  .my-upload-dialog-foot-btn {
    margin-bottom: 10px;
    width: 100px;
    height: 38px;
    border: 1px solid #cc1930;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 400;
    color: #cc1930;
  }
  .active {
    margin-right: 50px;
    color: #ffffff;
    background: linear-gradient(232deg, #cb1930, #ff4054);
    box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
  }
  .my-upload-dialog-btn:hover,
  .active:hover {
    border: none;
    background: linear-gradient(
      232deg,
      rgba(185, 48, 55, 0.8),
      rgba(239, 82, 91, 0.8)
    );
    box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
  }
}
</style>
