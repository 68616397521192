<template>
  <div class="admin">
    <schoolHead />
    <div class="admin-box">
      <div class="admin-box-route" v-if="!$route.meta.isRou">
        <adminRou />
      </div>
      <div class="admin-box-con">
        <router-view class="main_content" />
      </div>
    </div>
  </div>
</template>


<script>
import schoolHead from "@/components/school/schoolHead.vue";
import adminRou from "@/components/school/adminRou.vue";
export default {
  components: {
    schoolHead,
    adminRou,
  },
};
</script>

<style lang="scss" scoped>
.admin {
  background: #f1f1f1;
}
.admin-box-route {
  // padding: 40px 30px;
  // background: #fff;
  width: 100%;
  margin-top: -125px;
  height: 125px;
  background: rgba(51, 51, 51, 0.5);
  position:relative;
  z-index: 0;
  // opacity: 0.5;
}
.admin-box-con {
  // width: 1407px;
  margin: 0 auto;
}
</style>