<template>
  <div class="route">
    <ul class="route-list">
      <li>
        <router-link :to="{ path: '/school/home' }" class="schoolHome">
          <p class="school-home"></p>
          <p>校园首页</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{ path: '/admin/schoolHome' }" class="schoolHome">
          <p class="school-manage"></p>
          <p>园所管理</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{ path: '/admin/classroom' }" class="classroom">
          <p class="class-manage"></p>
          <p>课程中心</p>
        </router-link>
      </li>
      <!-- <li>
        <router-link :to="{ path: '/admin/onlineclass' }" class="onlineclass"
          >
          <p class="school-manage"></p>
          <p>在线课堂</p>
        </router-link>
      </li> -->
      <li>
        <a
          @click="toJumpT"
          class="declare"
          :class="{
            'router-link-active': $route.path.indexOf('/testconter/') > -1,
          }"
        >
          <p class="test-center"></p>
          <p>测试中心</p>
        </a>
      </li>
      <li>
        <a
          @click="toJump"
          class="declare"
          :class="{
            'router-link-active': $route.path.indexOf('/declare/') > -1,
          }"
        >
          <p class="school-declare"></p>
          <p>信息服务</p>
        </a>
      </li>
      <!-- <li>
        <a
          @click="toJump"
          class="declare"
          :class="{
            'router-link-active': $route.path.indexOf('/declare/') > -1,
          }"
        >
          <p class="school-declare"></p>
          <p>申报中心</p>
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script>
export default {
  name: "adminRou",
  data() {
    return {};
  },
  methods: {
    toJump() {
      this.$router.push({
        path: "/admin/declare",
      });
      // if (!this.$store.state.userInfo.teacherId) {
      // } else {
      //   this.$notice({
      //     message: "请使用园长账号申报",
      //   }).isShow();
      // }
    },
    toJumpT() {
      this.$router.push({
        path: "/admin/testconter",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.route {
  width: 1434px;
  margin: 0 auto;
}
.route-list {
  @include flex(row, space-between, center);
  li {
    cursor: pointer;

    a {
      display: block;
      color: #fff;
      width: 287px;
      height: 117px;
      padding-top: 10px;
      text-align: center;
      // line-height: 91px;
      font-size: 20px;

      color: rgba(255, 121, 128, 1);
      // background: url(../../assets/img/newschool/Icon_XYSY_U.png) no-repeat center 10px;
      > p:nth-child(1) {
        width: 66px;
        height: 68px;
        margin: 0 auto 0 auto;
      }
      .school-manage {
        background: url(../../assets/img/newschool/Icon_YSGL_U.png) no-repeat;
        background-size: cover;
      }
      .school-home {
        background: url(../../assets/img/newschool/Icon_XYSY_U.png) no-repeat;
        background-size: cover;
      }
      .class-manage {
        background: url(../../assets/img/newschool/Icon_KCZX.png) no-repeat;
        background-size: cover;
      }
      .test-center {
        background: url(../../assets/img/newschool/Icon_CSZX_U.png) no-repeat;
        background-size: cover;
      }
      // .school-declare {
      //   background: url(../../assets/img/newschool/Icon_YSSB_U.png) no-repeat;
      //   background-size: cover;
      // }
      .school-declare {
        background: url(../../assets/img/newschool/Icon_XXFW_UnC.png) no-repeat;
        background-size: cover;
      }
    }
    .router-link-active {
      background: url(../../assets/img/newschool/Bg_Tab_CH.png) no-repeat;
      background-size: cover;
      color: #ffffff;
      // background-size: 100% 100%;
      .school-manage {
        background: url(../../assets/img/newschool/Icon_YSGL_CH.png) no-repeat;
        background-size: cover;
      }
      .school-home {
        background: url(../../assets/img/newschool/Icon_XYSY_CH.png) no-repeat;
        background-size: cover;
      }
      .class-manage {
        background: url(../../assets/img/newschool/Icon_KCZX_CH.png) no-repeat;
        background-size: cover;
      }
      .test-center {
        background: url(../../assets/img/newschool/Icon_CSZX_CH.png) no-repeat;
        background-size: cover;
      }
      // .school-declare {
      //   background: url(../../assets/img/newschool/Icon_YSSB_CH.png) no-repeat;
      //   background-size: cover;
      // }
      .school-declare {
        background: url(../../assets/img/newschool/Icon_XXFW_C.png) no-repeat;
        background-size: cover;
      }
    }
  }
  li:hover {
    background: url(../../assets/img/newschool/Bg_Tab_CH.png) no-repeat;
    a {
      color: #ffffff;
    }

    .school-manage {
      background: url(../../assets/img/newschool/Icon_YSGL_CH.png) no-repeat;
      background-size: cover;
    }
    .school-home {
      background: url(../../assets/img/newschool/Icon_XYSY_CH.png) no-repeat;
      background-size: cover;
    }
    .class-manage {
      background: url(../../assets/img/newschool/Icon_KCZX_CH.png) no-repeat;
      background-size: cover;
    }
    .test-center {
      background: url(../../assets/img/newschool/Icon_CSZX_CH.png) no-repeat;
      background-size: cover;
    }
    // .school-declare {
    //   background: url(../../assets/img/newschool/Icon_YSSB_CH.png) no-repeat;
    //   background-size: cover;
    // }
    .school-declare {
      background: url(../../assets/img/newschool/Icon_XXFW_C.png) no-repeat;
      background-size: cover;
    }
  }
}
</style>